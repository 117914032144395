import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { setError, startLoading, stopLoading } from "../components/Notification"
import { getMutex } from '../utils/simpleMutex'
import { EMPTY_GUID } from '../utils/constants'
import { listEntitiesAsync } from '../api'

const EntityState = {
  _entities: new Map(),
  _mutex: getMutex(),
  _fatalError: false,
  clearAll() {
    this._entities.clear()
  },
  async getEntitiesAsync(instance, apiEntity, filter = "") {
    const [lock, release] = this._mutex.getLock()
    await lock
    if (!this._entities.has(`${apiEntity}${filter}`) && !this._fatalError) {
      const result = await listEntitiesAsync(instance, apiEntity, filter)
      if (!Array.isArray(result)) {
        this._fatalError = true
      } else {
        this._entities.set(`${apiEntity}${filter}`, result)
      }
    } else {
      // console.log(`reading from cache ${apiEntity}  ${filter}`)
    }
    release()
    return this._entities.get(`${apiEntity}${filter}`) || []
  }
}

const DisplayEntity = {
  template: `
    <span v-show="!!selectedEntity">{{ selectedEntity?.text }}</span>
  `,
  props: {
    apiEntity: String,
    filter: String,
    modelValue: String
  },
  setup(props, { emit }) {
    const { instance } = useMsal()

    const entities = ref([])
    const filter = toRef(props, 'filter')

    const loadAsync = async () => {
      entities.value = await EntityState.getEntitiesAsync(instance, props.apiEntity, filter.value)
    }

    const selectedEntity = computed(() => entities.value.find(e => e.id === props.modelValue))

    onMounted(async () => loadAsync())

    return { selectedEntity, entities }
  }
}

const SelectEntity = {
  template: `
    <div>
      <select
        :class="{ 'is-invalid': modelValue === $EMPTY_GUID && required }"
        :disabled="!canChange"
        v-show="activeEntities.length > 0 && activeEntities.length < limit" class="form-select"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value); $emit('changed', $event.target.value);">
          <option :value="$EMPTY_GUID" v-show="!required"></option>
          <option v-for="w in activeEntities" :value="w.id">{{ w.text }}</option>
      </select>

      <Autocomplete
        :max="20"
        :required="required"
        ref="autoCompleteEl"
        :class="{ 'form-control': true, 'is-invalid': modelValue === $EMPTY_GUID && required }"
        v-show="activeEntities.length > limit && !selectedEntity && canChange"
        @input="q => { autoCompleteQ = q }"
        @onSelect="select"
        :results="filteredEntities"></Autocomplete>
      <div class="row" v-show="activeEntities.length > limit && !!selectedEntity" @click="emitClear" role="button">
        <div class="col-8">{{ selectedEntity?.text }}</div>
        <div class="col-4 text-end" v-show="canChange"><button class="btn btn-sm btn-secondary">{{ $t("common.change") }}</button></div>
      </div>

      <div class="alert alert-warning" role="alert" v-show="selectedEntity?.strike">{{ $t("common.poiFullyShipped") }}</div>

      <div class="alert alert-warning" role="alert" v-show="!activeEntities.length">{{ noResultsMessage ?? $t("common.noHits") }}</div>

    </div>
`,
  props: {
    apiEntity: String,
    filter: String,
    modelValue: String,
    canChange: { type: Boolean, default: true },
    noResultsMessage: String,
    limit: { type: Number, default: 20 },
    required: { type: Boolean, default: false },
    disableIsActive: { type: Boolean, default: false }
  },
  emits: ['update:modelValue', 'changed'],
  setup(props, { emit }) {
    const { instance } = useMsal()
    const entities = ref([])
    const autoCompleteEl = ref(null)
    const autoCompleteQ = ref("")

    const filter = toRef(props, 'filter')

    /*const reset = () => {
      autoCompleteQ.value = ""
      autoCompleteEl.value?.setText("")
    }*/

    const select = s => {
      const result = props.disableIsActive ? entities.value.find(e => e?.text === s) : entities.value.find(e => e?.text === s && e?.isActive === true)
      if (!result) {
        console.error(`SelectEntity: select: ${s} not found`)
        return
      }
      autoCompleteQ.value = result.text
      emit('update:modelValue', result.id)
      emit('changed', result.id)
    }

    const activeEntities = computed(() => props.disableIsActive ? entities.value : entities.value.filter(e => e?.isActive === true))

    const filteredEntities = computed(() => {
      if (autoCompleteQ.value?.length > 0) {
        return activeEntities.value.filter(e => e.text.toLowerCase().includes(autoCompleteQ.value.toLowerCase())).map(e => e.text)
      }
      return []
    })

    const loadAsync = async () => {
      entities.value = await EntityState.getEntitiesAsync(instance, props.apiEntity, filter.value)
      if (entities.value.length === 1) {
        emit('update:modelValue', entities.value[0].id)
        emit('changed', entities.value[0].id)
      }
    }

    const emitClear = async () => {
      if (props.canChange) {
        emit('update:modelValue', EMPTY_GUID)
        emit('changed', EMPTY_GUID)
      }
    }

    const selectedEntity = computed(() => entities.value.find(e => e.id === props.modelValue))

    watch(() => filter.value, async (curr, next) => await loadAsync())

    watch(() => props.apiEntity, async (curr, next) => await loadAsync())

    onMounted(async () => loadAsync())

    return { selectedEntity, entities, activeEntities, autoCompleteEl, autoCompleteQ, filteredEntities, select, emitClear }
  }
}

export { SelectEntity, EntityState, DisplayEntity }
