import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { setError, startLoading, stopLoading, setSuccess } from "../components/Notification"
import { getEntityAsync, saveEntityAsync } from "../api"

export function useShipment(instance, route, router) {
  const entity = ref(null)

  const poiShippedQuantityExt = ref(new Map())

  const poiShippedQuantityHere = ref(new Map())

  const purchaseOrders = computed(() => purchaseOrderItems.value?.map((poi) => poi.po).unique())

  const purchaseOrderItems = computed(() =>
    entity.value?.pallets.flatMap((sp) => sp.masterCartons.flatMap((mc) => mc.innerCartons.flatMap((ic) => ic.items)))
  )

  watch(
    () => entity.value,
    async (newValue, oldValue) => {
      purchaseOrderItems.value?.forEach(async (poi) => {
        if (!poiShippedQuantityExt.value.has(poi.purchaseOrderItemId)) {
          poiShippedQuantityExt.value.set(poi.purchaseOrderItemId)
          const purchaseOrderItem = await getEntityAsync(
            instance,
            `PurchaseOrderItem`,
            poi.purchaseOrderItemId,
            `?excludeShipmentID=${entity.value.id}`
          )
          poiShippedQuantityExt.value.set(poi.purchaseOrderItemId, {
            shipped: purchaseOrderItem?.shippedQuantity || 0,
            workOrder: purchaseOrderItem?.workOrder,
          })
        }
        poiShippedQuantityHere.value.set(
          poi.purchaseOrderItemId,
          purchaseOrderItems.value
            ?.filter((p) => p.purchaseOrderItemId === poi.purchaseOrderItemId)
            .reduce((acc, x) => acc + x.quantity, 0)
        )
      })
    },
    { deep: true }
  )

  const _flattenInnerCarton = (ic) => {
    const result = []
    if (!ic.items.length) {
      result.push({ skuCount: 0 })
    }
    let firstRow = true
    ic.items.forEach((sku) => {
      result.push({ ...sku, skuCount: ic.items.length, skipSku: !firstRow })
      firstRow = false
    })
    return result
  }

  const _flattenMasterCarton = (mc) => {
    const result = []
    if (!mc.innerCartons.length) {
      result.push({})
      return result
    }
    let rowsInMaster = 0
    mc.innerCartons.forEach((ic) => {
      const items = _flattenInnerCarton(ic)
      rowsInMaster += items.length
      const data = items.map((sku) => {
        return {
          ...sku,
          innerIdent: ic.ident,
          innerIsHidden: ic.isHidden,
        }
      })
      result.push(...data)
    })
    let isFirstRow = true
    result.forEach((ic) => {
      ic.rowsInMaster = rowsInMaster
      ic.skipInnerCarton = !isFirstRow
      isFirstRow = false
    })
    return result
  }

  const _flattenPallet = (pallet) => {
    const result = []
    if (!pallet.masterCartons.length) {
      result.push({})
      return result
    }
    let rowsInPallet = 0
    // let masterNumberWithinPallet = 1
    pallet.masterCartons.forEach((mc) => {
      const inners = _flattenMasterCarton(mc)
      const hasComment = !!mc.comment?.length
      totalRowsInMaster = (inners[0].rowsInMaster || 1) + (hasComment ? 1 : 0)
      rowsInPallet += totalRowsInMaster
      const isEmptyMaster = !inners[0].rowsInMaster
      const data = inners.map((ic) => {
        return {
          from: mc.from,
          to: mc.to,
          masterIdent: mc.ident,
          grossWeight: mc.grossWeight,
          netWeight: mc.netWeight,
          length: mc.length,
          width: mc.width,
          height: mc.height,
          masterQuantity: mc.quantity,
          comment: mc.comment,
          hasComment,
          isNewMc: mc.isNew,
          masterCanChange: mc.canChange,
          dateCreated: mc.dateCreated,
          dateModified: mc.dateModified,
          userCreatedID: mc.userCreatedID,
          userModifiedID: mc.userModifiedID,
          totalRowsInMaster,
          isEmptyMaster,
          ...ic,
        }
      })
      result.push(...data)
      if (hasComment) {
        result.push({
          comment: mc.comment,
          skipInnerCarton: true,
          isComment: true,
        })
      }
      // masterNumberWithinPallet += mc.quantity
    })
    let isFirstRow = true
    result.forEach((ic) => {
      ic.rowsInPallet = rowsInPallet
      ic.skipMasterCarton = !isFirstRow
      isFirstRow = false
    })
    return result
    // NON-WORKING SORTING.
    // THE PROBLEM IS THAT DISPLAYING EXPECTS THE ORDER WITHIN THE MC TO BE UNCHANGED
    // return result.sort((a, b) => {
    //   const aIdentNumber = parseInt(a.masterIdent?.replace("MC-", "") || 0, 10);
    //   const bIdentNumber = parseInt(b.masterIdent?.replace("MC-", "") || 0, 10);
    //   return aIdentNumber - bIdentNumber;
    // })
  }

  const flatMasterCartons = computed(() => entity.value?.pallets.flatMap((sp) => sp.masterCartons))

  const addPallet = (addOneMasterCartonToo = false) => {
    if (!entity.value) {
      return
    }
    const palletID = "P-" + (entity.value.pallets.length + 1)
    entity.value.pallets.push({ palletID, masterCartons: [] })
    if (addOneMasterCartonToo) {
      addMasterCarton(palletID)
    }
    window.scrollTo(0, document.body.scrollHeight)
  }

  const hasUnsavedMasterCarton = ref(false)
  const addMasterCarton = (palletID) => {
    hasUnsavedMasterCarton.value = true
    const pallet = _getPallet(palletID)
    const next =
      flatMasterCartons.value.length === 0
        ? 1
        : Math.max(...flatMasterCartons.value.map((mc) => parseInt(mc.ident.substring(3)))) + 1
    console
    const mc = {
      ident: "MC-" + next,
      innerCartons: [],
      quantity: 1,
      isNew: true,
      masterCartonPalletId: palletID,
      canChange: true,
    }
    pallet.masterCartons.push(mc)
  }

  const addInnerCarton = (palletID, masterIdent, isHidden = false) => {
    const masterCarton = _getMasterCarton(palletID, masterIdent)
    const innerCarton = {
      ident: "IC-" + (masterCarton.innerCartons.length + 1),
      items: [],
      isHidden,
    }
    masterCarton.innerCartons.push(innerCarton)
    return innerCarton
  }

  const _getSku = (row) => {
    // TODO: doesn't work well for multiple same SKUs in same inner carton
    const innerCarton = _getInnerCarton(row.palletID, row.masterIdent, row.innerIdent)
    if (!innerCarton) {
      return null
    }
    const sku = innerCarton.items.find((sku) => sku.sku === row.sku && sku.quantity === row.quantity)
    return sku
  }

  const removeSku = (row) => {
    const skuToRemove = _getSku(row)
    const innerCarton = _getInnerCarton(row.palletID, row.masterIdent, row.innerIdent)
    const alsoRemoveInnerCarton = innerCarton.items.length === 1
    if (alsoRemoveInnerCarton) {
      const masterCarton = _getMasterCarton(row.palletID, row.masterIdent)
      const index = masterCarton.innerCartons.indexOf(innerCarton)
      if (index > -1) {
        masterCarton.innerCartons.splice(index, 1)
      }
    } else {
      const index = innerCarton.items.indexOf(skuToRemove)
      if (index > -1) {
        innerCarton.items.splice(index, 1)
      }
    }
  }

  const _getPallet = (palletID) => {
    const pallet = entity.value.pallets.find((sp) => sp.palletID === palletID)
    if (!pallet) {
      console.log(entity.value.pallets)
      throw new Error("Pallet " + palletID + " not found ")
    }
    return pallet
  }

  const _getMasterCarton = (palletID, masterIdent) => {
    const pallet = _getPallet(palletID)
    const masterCarton = pallet.masterCartons.find((c) => c.ident === masterIdent)
    if (!masterCarton) {
      console.log(pallet.masterCartons)
      throw new Error("Master carton not found " + masterIdent)
    }
    return masterCarton
  }

  const _getInnerCarton = (palletID, masterIdent, innerIdent) => {
    const masterCarton = _getMasterCarton(palletID, masterIdent)
    const innerCarton = masterCarton.innerCartons.find((c) => c.ident === innerIdent)
    if (!innerCarton) {
      console.log(masterCarton)
      throw new Error("Inner carton not found " + innerIdent)
    }
    return innerCarton
  }

  const loadShipmentAsync = async () => {
    if (!route.params.id) {
      entity.value = null
      return
    }
    entity.value = await getEntityAsync(instance, "Shipment", route.params.id)
    hasUnsavedMasterCarton.value = false
    if (!entity.value) {
      setError("Ne obstaja.")
    }
  }

  const saveShipmentAsync = async () => {
    const result = await saveEntityAsync(instance, "Shipment", entity.value)
    if (result) {
      setSuccess("Shranjeno.")
      if (entity.value.isNew) {
        router.push({
          name: "Shipment",
          params: { id: entity.value.id, keepMessages: true },
        })
      } else {
        await loadShipmentAsync()
      }
    } else {
      setError("Napaka pri shranjevanju.")
    }
    return result
  }

  const rows = computed(() => {
    const flattenedData = []
    entity.value?.pallets.forEach((pallet) => {
      const masters = _flattenPallet(pallet)
      const data = masters.map((mc) => {
        const orderedQuantity = purchaseOrderItems.value
          ?.filter((poi) => poi.sku === mc.sku && poi.po === mc.po)
          .reduce((acc, poi) => acc + poi.quantity, 0)
        return {
          palletID: pallet.palletID,
          ...mc,
          orderedQuantity,
        }
      })
      flattenedData.push(...data)
    })
    return flattenedData
  })

  const extendedBoxes = computed(() => {
    const result = []
    rows.value.forEach((row) => {
      for (let i = 0; i < row.masterQuantity; i++) {
        const rowToAdd = { ...row }
        const boxIdentifier =
          "S" +
          entity.value.shipmentNumber +
          "-" +
          row.palletID.replace("-", "") +
          "-M" +
          (row.from + i) +
          "-" +
          (row.innerIdent ? row.innerIdent : "NIC")
        let boxPrint = "S" + entity.value.shipmentNumber
        if (entity.value.hasPallets) {
          boxPrint += "-" + row.palletID.replace("-", "")
        }
        boxPrint += "-M" + (row.from + i)
        if (!row.innerIsHidden && row.innerIdent) {
          boxPrint += "-" + row.innerIdent
        }
        rowToAdd.extendedMasterNumber = boxIdentifier
        rowToAdd.boxPrint = boxPrint
        rowToAdd.barCode = boxIdentifier
        rowToAdd.comment = row.comment
        result.push(rowToAdd)
      }
    })
    return result
  })

  return {
    entity,
    rows,
    addPallet,
    addInnerCarton,
    addMasterCarton,
    _getInnerCarton,
    _getPallet,
    removeSku,
    flatMasterCartons,
    _getMasterCarton,
    loadShipmentAsync,
    saveShipmentAsync,
    purchaseOrders,
    _getSku,
    poiShippedQuantityExt,
    poiShippedQuantityHere,
    extendedBoxes,
    hasUnsavedMasterCarton,
  }
}
