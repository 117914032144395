/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { useMsal } from '../composition-api/useMsal'
import { getEntityAsync } from "../api.js"

export const SelectPurchaseOrderItem = {
  template: `
  <Modal :visible="visible" @close="$emit('close'); purchaseOrderItemId = null; selectedPu = null" :title="$t('SelectPurchaseOrderItem.select')">
    <div class="row">
      <div class="col">
        <div class="row mb-3">
          <div class="col-lg-6">
            <SelectEntity
              apiEntity="PurchaseOrders/Open?variant=Select"
              v-model="selectedPo"
              :disableIsActive="true"
              @changed="e => { if (e) { $emit('selectedPo', e);  }}"  />
          </div>
        </div>
        <div class="row mb-3" v-if="selectedPo">
          <div class="col-lg-6">
            <SelectEntity :apiEntity="poItemsApiEntity" v-model="purchaseOrderItemId" :disableIsActive="true" />
          </div>
        </div>
      </div>
    </div>

    <template v-slot:buttons>
      <button class="btn btn-success ml-1" type="button" @click="selectSkuAsync" :disabled="!purchaseOrderItemId">{{ $t('SelectPurchaseOrderItem.add') }}</button>
    </template>
  </Modal>
  `,
  emits: ["close", "selected", "selectedPo"],
  props: {
    visible: { type: Boolean, default: false },
    po: { type: String }
  },
  setup(props, { emit }) {
    const { instance } = useMsal()
    const purchaseOrderItemId = ref(null)
    const selectedPo = ref()

    const poItemsApiEntity = computed(() => `PurchaseOrderItems/${selectedPo.value}/?variant=Select`)

    watch(() => props.po, () => selectedPo.value = props.po)

    const selectSkuAsync = async () => {
      const purchaseOrderItem = await getEntityAsync(instance, `PurchaseOrderItem`, purchaseOrderItemId.value)
      if (purchaseOrderItem) {
        purchaseOrderItemId.value = null
        purchaseOrderItem.quantity = 0
        emit('selected', purchaseOrderItem)
        emit('close')
      }
    }

    return { purchaseOrderItemId, selectedPo, poItemsApiEntity, selectSkuAsync }
  }
}
