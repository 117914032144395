import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onUnmounted } from 'vue'
import { listEntitiesAsync } from "../api"
import { useMsal } from "./useMsal"

export function useSummary() {
  const { instance } = useMsal()

  const summary = ref([])

  const loadShipmentSummary = async shipmentId => {
    if (!shipmentId || shipmentId === 'new') summary.value = []
    else summary.value = await listEntitiesAsync(instance, `ShipmentSummary/${shipmentId}`)
  }

  const summaryTotal = computed(() => {
    if (!summary.value?.length) {
      return { orderQty: 0, shipQty: 0, shortQty: 0 }
    }
    const orderQty = summary.value.reduce((a, b) => a + b.orderQty, 0)
    const shipQty = summary.value.reduce((a, b) => a + b.shipQty, 0)
    const shortQty = shipQty - orderQty

    return { orderQty, shipQty, shortQty }
  })

  const atLeastOneOutstandingIsNegative = computed(() => {
    if (!summary.value?.length) {
      return false
    }
    return summary.value.some(s => s.orderQty - s.shipQty < 0)
  })

  return { summary, loadShipmentSummary, summaryTotal, atLeastOneOutstandingIsNegative }
}
