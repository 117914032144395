export default ShipmentSummary = {
  name: "ShipmentSummary",
  template: `<div class="row mt-5" v-if="summary.length">
    <div class="col">
      <h4 class="no-print">{{ $t('shipment.summaryNote') }}</h4>
      <div class="table-responsive">
        <table class="table table-stripped table-sm table-sc">
          <thead>
            <tr>
              <th scope="col">{{ $t('shipment.style') }}</th>
              <th scope="col">{{ $t('shipment.PO') }}</th>
              <th scope="col">{{ $t('shipment.color') }}</th>
              <th scope="col">{{ $t('shipment.size') }}</th>
              <th scope="col">{{ $t('shipment.orderQty') }}</th>
              <th scope="col">{{ $t('shipment.shipQty') }}</th>
              <th scope="col">{{ $t('shipment.shortQty') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in summary">
              <td>{{ row.sifpre }}</td>
              <td>{{ row.po }}</td>
              <td>{{ row.sifbar }}</td>
              <td>{{ row.sifvel }}</td>
              <td>{{ row.orderQty }}</td>
              <td>{{ row.shipQty }}</td>
              <td>{{ -(row.orderQty - row.shipQty) }}</td>
            </tr>
            <tr class="total" v-if="summaryTotal">
              <td colspan="4" class="first-total">{{ $t('shipment.TOTAL') }}</td>
              <td>{{ summaryTotal.orderQty }}</td>
              <td>{{ summaryTotal.shipQty }}</td>
              <td>{{ -summaryTotal.shortQty }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>`,
  props: {
    summaryTotal: { type: Object, default: null },
    summary: {
      type: Array,
      default: []
    },
  }
}
