import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { startLoading, stopLoading, setError } from "../components/Notification"

import { forgotPasswordRequest } from "../authConfig"

/**
 * Helper function to determine whether 2 arrays are equal
 * Used to avoid unnecessary state updates
 * @param arrayA
 * @param arrayB
 */
function accountArraysAreEqual(arrayA, arrayB) {
  if (arrayA.length !== arrayB.length) {
    return false
  }

  const comparisonArray = [...arrayB]

  return arrayA.every((elementA) => {
    const elementB = comparisonArray.shift()
    if (!elementA || !elementB) {
      return false
    }

    return (
      elementA.homeAccountId === elementB.homeAccountId &&
      elementA.localAccountId === elementB.localAccountId &&
      elementA.username === elementB.username
    )
  })
}

export const msalPlugin = {
  install: (app, msalInstance) => {
    startLoading("msal")

    const inProgress = msal.InteractionStatus.Startup
    const accounts = msalInstance.getAllAccounts()

    const state = reactive({
      instance: msalInstance,
      inProgress: inProgress,
      accounts: accounts,
    })

    app.config.globalProperties.$msal = state

    msalInstance.addEventCallback((message) => {
      switch (message.eventType) {
        case msal.EventType.ACCOUNT_ADDED:
        case msal.EventType.ACCOUNT_REMOVED:
        case msal.EventType.LOGIN_SUCCESS:
        case msal.EventType.SSO_SILENT_SUCCESS:
        case msal.EventType.HANDLE_REDIRECT_END:
        case msal.EventType.SSO_SILENT_FAILURE:
        case msal.EventType.LOGOUT_END:
        case msal.EventType.ACQUIRE_TOKEN_SUCCESS:
        case msal.EventType.ACQUIRE_TOKEN_FAILURE:
          const currentAccounts = msalInstance.getAllAccounts()
          if (!accountArraysAreEqual(currentAccounts, state.accounts)) {
            state.accounts = currentAccounts
          }
          break
        case msal.EventType.LOGIN_FAILURE:
          if (message.error?.errorMessage?.indexOf("AADB2C90118") > -1) {
            msalInstance.loginRedirect(forgotPasswordRequest)
          }
          break
      }
      if (message?.eventType === msal.EventType.HANDLE_REDIRECT_END) {
        stopLoading("msal")
      }
      const status = msal.EventMessageUtils.getInteractionStatusFromEvent(message, state.inProgress)
      if (status !== null) {
        state.inProgress = status
      }
    })
  },
}
