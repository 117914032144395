import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { getEntityAsync, getMyPartnerAsync, listEntitiesAsync } from "../api"
import { useMsal } from "../composition-api/useMsal"

const Home = {
  template: `
  <div class="top-container">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="new-shipment">
            <div>
              <h5>{{ $t('home.newShipmentShort') }}</h5>
              <p>{{ $t('home.newShipmentLong') }}</p>
            </div>
            <div><button class="btn-new-shippment" type="button" @click="$router.push({ name: 'Shipment', params: { id: 'new' } })">{{ $t('home.newShipmentShort') }}</button></div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="bg-lisca" v-if="partner">
            <h5>{{ partner.name }}</h5>
            <p>{{ partner.address }}</p>
            <h6>{{ partner.postCode }} {{ partner.country }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row mb-3 mt-5">

      <div class="col-12 col-lg-8">
        <h3 class="mt-34">{{ $t('home.purchaseOrders') }}</h3>
        <table class="table table-stripped table-sm table-responsive table-rw" v-if="purchaseOrders?.length">
          <thead class="thead-light">
            <tr>
              <th scope="col">{{ $t('home.PO') }}</th>
              <th scope="col">{{ $t('home.series') }}</th>
              <th scope="col">{{ $t('home.skuNumber') }}</th>
              <th scope="col">{{ $t('home.totalQuantity') }}</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="po in purchaseOrders">
                <td><strong>{{ po.po }}</strong></td>
                <td>{{ po.series }}</td>
                <td>{{ po.lineItemsCount }}</td>
                <td>{{ po.lineItemsQuantity }}</td>
              </tr>
          </tbody>
        </table>
        <div class="col justify-content-center" v-else>
          <p>{{ $t('home.noPoFound') }}</p>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="sidebar">
          <h3>{{ $t('home.shipments') }}</h3>
          <table class="table table-stripped table-sm table-hover table-responsive" v-if="shipments?.length">
            <thead class="thead-light">
              <tr>
                <th scope="col">{{ $t('home.shipmentNumber') }}</th>
                <th scope="col">{{ $t('home.shipmentType') }}</th>
                <th scope="col">{{ $t('home.shipmentStatus') }}</th>
                <th scope="col">{{ $t('home.shipmentDate') }}</th>
              </tr>
            </thead>
            <tbody>
                <tr role="button" v-for="s in shipments" @click="select(s)">
                  <td><strong>{{ s.shipmentNumber }}</strong></td>
                  <td>{{ s.shipmentType }} </td>
                  <td><OrderBadge :status="s.status" /></td>
                  <td>{{ s.date }}</td>
                </tr>
            </tbody>
          </table>
          <div class="col justify-content-center" v-else>
            <p>{{ $t('home.noShipmentFound') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  setup() {
    const router = VueRouter.useRouter()

    const select = (entity) => {
      const routeData = router.resolve({ name: "Shipment", params: { id: entity.id } })
      router.push(routeData)
    }

    const { instance } = useMsal()

    const partner = ref()
    const purchaseOrders = ref()
    const shipments = ref()

    onMounted(async () => {
      partner.value = await getMyPartnerAsync(instance)
      purchaseOrders.value = await listEntitiesAsync(instance, "PurchaseOrders/Open")
      shipments.value = await listEntitiesAsync(instance, "Shipments")
    })

    return { partner, purchaseOrders, shipments, select }
  },
}

export default Home
