import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { fetchAuthenticated } from "../utils/fetchAuthenticated"
import { useMsal } from '../composition-api/useMsal'

const shipmentTypes = ref(new Map())

export const useEnums = () => {
  const { instance } = useMsal()

  const loadEnumsAsync = async () => {
    console.log("loadEnumsAsync")
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Enums`, "GET")
    if (!raw.shipmentTypes) {
      throw raw
    }
    shipmentTypes.value = new Map(raw.shipmentTypes.map(value => ([value, text = value])))
  }

  return { shipmentTypes, loadEnumsAsync }
}

