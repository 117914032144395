import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'

const Paging = {
  template: `
  <nav>
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: paging.pageNum === 1 }" role="button">
        <a class="page-link" id="back" @click="previousPage">{{ $t("common.prev") }}</a>
      </li>
      <li class="page-item" :class="{ disabled: paging.pageNum === paging.pages || paging.pages <= 1 }" role="button">
        <a class="page-link" id="forward" @click="nextPage">{{ $t("common.next") }}</a>
      </li>
    </ul>
  </nav>
  <div class="row">
    <div class="col">
      <p class="justify-content-center">
      {{ $t("common.page") }} {{ paging.pageNum }} {{ $t("common.pageOf") }} {{ paging.pages }}. <span>{{ $t("common.total") }} {{ paging.numberOfRecords }}</span>
      </p>
    </div>
  </div>
`,
  emits: ["change"],
  props: {
    paging: Object
  },
  setup(props, { emit }) {
    const previousPage = () => {
      if (props.paging.pageNum === 1) return
      emit("change", props.paging.pageNum - 1)
    }

    const nextPage = () => {
      if (props.paging.pageNum >=  props.paging.pages) return
      emit("change", props.paging.pageNum + 1)
    }

    return {
      previousPage,
      nextPage
    }
  }
}

const usePaging = updateFunc => {
  if (!updateFunc instanceof Function ) {
    throw TypeError("updateFunc must be async function")
  }

  const dirty = ref({ v: 0 })

  const paging = ref({
    numberOfRecords: null,
    pageNum: 1,
    itemsPerPage: 20,
    pages: 0
  })

  const resetPaging = () => {
    paging.value.numberOfRecords = 0
    paging.value.pages = 0
    paging.value.pageNum = 1
    dirty.value.v++
  }

  const setPageNum = n => {
    if (!Number.isInteger(n)) {
      throw "setPageNum should be integer"
    }
    paging.value.pageNum = n
    dirty.value.v++
  }

  const setNumberOfRecords = numberOfRecords => {
    if (!Number.isInteger(numberOfRecords)) {
      throw "numberOfRecords should be integer"
    }
    paging.value.numberOfRecords = numberOfRecords
    paging.value.pages = Math.ceil(paging.value.numberOfRecords / paging.value.itemsPerPage)
  }

  watch(dirty.value, async () => {
    console.log("paging updateFunc")
    await updateFunc()
  })

  return {
    paging,
    resetPaging,
    setNumberOfRecords,
    setPageNum
  }
}



export { Paging, usePaging }
