import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { patchEntityAsync } from "../api"
import { setError, setSuccess } from '../components/Notification'
import { useMsal } from '../composition-api/useMsal'

export const MasterCartonModal = {
  template: `<Modal :visible="!!masterCarton" @close="$emit('close')" :title="$t('shipment.editMasterCarton')">
  <div class="row" v-if="masterCarton">
    <div class="col">
      <div class="row mt-3">
        <label class="col-form-label col-sm-4">{{ $t('shipment.grossWeight') }} *</label>
        <div class="col-sm-8">
          <div class="input-group">
            <input
              class="form-control"
              v-model="masterCarton.grossWeight"
              type="number"
              step=".1"
              :disabled="!canChange"
              :class="{ 'is-invalid': (masterCarton.grossWeight <= masterCarton.netWeight) }" />
            <span class="input-group-text">kg</span>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-form-label col-sm-4">{{ $t('shipment.netWeight') }} *</label>
        <div class="col-sm-8">
          <div class="input-group">
            <input
              class="form-control"
              v-model="masterCarton.netWeight"
              type="number"
              step=".1"
              :disabled="!canChange"
              :class="{ 'is-invalid': (masterCarton.grossWeight <=masterCarton.netWeight) }" />
            <span class="input-group-text">kg</span>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-form-label col-sm-4">{{ $t('shipment.masterQuantity') }}</label>
        <div class="col-sm-8">
          <div class="input-group">
            <input class="form-control" v-model="masterCarton.masterQuantity" type="number" step="1" :disabled="!canChange" required min="1" />
            <span class="input-group-text">{{ $t('shipment.cartonUnits') }}</span>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-form-label col-sm-4">{{ $t('shipment.length') }}</label>
        <div class="col-sm-8">
          <div class="input-group">
            <input class="form-control" v-model="masterCarton.length" type="number" step=".1" :disabled="!canChange" />
            <span class="input-group-text">cm</span>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-form-label col-sm-4">{{ $t('shipment.width') }}</label>
        <div class="col-sm-8">
          <div class="input-group">
            <input class="form-control" v-model="masterCarton.width" type="number" step=".1" :disabled="!canChange" />
            <span class="input-group-text">cm</span>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-form-label col-sm-4">{{ $t('shipment.height') }}</label>
        <div class="col-sm-8">
          <div class="input-group">
            <input class="form-control" v-model="masterCarton.height" type="number" step=".1" :disabled="!canChange" />
            <span class="input-group-text">cm</span>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <label class="col-form-label col-sm-4">{{ $t('shipment.comment') }}</label>
        <div class="col-sm-8">
          <div class="input-group">
            <textarea class="form-control" v-model="masterCarton.comment" :disabled="!canChange" rows="3" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <template v-slot:buttons>
    <button class="btn btn-delete me-2 ms-2" type="button" :disabled="!canChange" @click="$emit('delete', masterCarton)">{{ $t('common.delete') }}</button>
    <button class="btn btn-save" type="button" :disabled="!canChange || masterCarton.masterQuantity < 1 || !isValid" @click="$emit('success', masterCarton)">{{ $t('common.save') }}</button>
  </template>
</Modal>`,
  props: {
    canChange: { type: Boolean, default: false },
    masterCarton: {
      type: Object,
      default: null
    },
  },
  emits: [ 'close', 'success', 'delete'],
  setup(props, { emit }) {
    // const { instance } = useMsal()

    const isValid = computed(() => {
      const mc = props.masterCarton
      console.log(mc)
      return mc && mc.grossWeight >= mc.netWeight && mc.grossWeight > 0 && mc.netWeight > 0
    })


    return { isValid }
  }
}
