/* global TENANT_NAME, CLIENT_ID, REDIRECT_URI, msal, LOCALE */

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/B2C_1_signupsignin1`,
    knownAuthorities: [`${TENANT_NAME}.b2clogin.com`],
    redirectUri: `${REDIRECT_URI}`,
  },
  cache: {
    cacheLocation: "localStorage",
    // storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error(message);
            return;
          /*case msal.LogLevel.Info:
              console.info(message)
              return
          case msal.LogLevel.Verbose:
              console.debug(message)
              return
          case msal.LogLevel.Warning:
              console.warn(message)
              return*/
          default:
            return;
        }
      },
      logLevel: msal.LogLevel.Verbose,
    },
  },
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);

export const forgotPasswordRequest = {
  authority: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com/B2C_1_password_reset`,
  knownAuthorities: [`${TENANT_NAME}.b2clogin.com`],
  scopes: [`https://${TENANT_NAME}.onmicrosoft.com/api/base`],
  // extraQueryParameters: { ui_locales: LOCALE === 'si' ? 'sl' : LOCALE },
};

export const loginRequest = {
  scopes: ["openid", "profile", CLIENT_ID],
  // extraQueryParameters: { ui_locales: LOCALE }
};
