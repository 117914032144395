import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useShipment } from "../composition-api/useShipment"
import { useMsal } from "../composition-api/useMsal"
import { getEntityAsync, listEntitiesAsync } from "scripts/api"
import ShipmentSummary from "../components/ShipmentSummary"
import { useSummary } from "../composition-api/useSummary"

export default Packlist = {
  name: "Packlist",
  components: { ShipmentSummary },
  template: `
  <div class="packlist">
    <div  class="top-container">
      <div class="container" v-if="entity">
        <div class="row mb-3">
          <div class="col text-center print-only" v-if="partner?.imageBase64">
            <img :src="'data:image/jpeg;base64, ' + partner.imageBase64"  />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="from" v-if="partner">
              <h6>{{ $t('packlist.from') }}</h6>
              <h5>{{ partner.name }}</h5>
              <p>{{ partner.address }}</p>
              <h4>{{ partner.postCode }} {{ partner.country }}</h4>
            </div>
            <div class="to">
              <h6>{{ $t('packlist.to') }}</h6>
              <h5>Lisca d.o.o.</h5>
              <p>Prešernova 4</p>
              <h4>8290 Sevnica</h4>
            </div>
          </div>
          <div class="col">
            <div class="overview">
              <h2>{{ $t("packlist.overview") }}</h2>
              <table>
                <tr>
                  <td>{{ $t('packlist.shipmentNumber') }}</td>
                  <td>{{ entity.shipmentNumber }}</td>
                </tr>
                <tr>
                  <td>{{ $t('packlist.shipmentdate') }}</td>
                  <td>{{ entity.date }}</td>
                </tr>
                <tr>
                  <td>{{ $t('packlist.shipmentType') }}</td>
                  <td>{{ entity.shipmentType }}</td>
                </tr>
                <tr>
                  <td>{{ $t('packlist.billOfLanding') }}</td>
                  <td>{{ entity.billOfLanding }}</td>
                </tr>
                <tr>
                  <td>{{ $t('packlist.comInvoiceNo') }}</td>
                  <td>{{ entity.comInvoiceNo }}</td>
                </tr>
                <tr>
                  <td>{{ $t('packlist.shippedVia') }}</td>
                  <td>{{ entity.shippedVia }}</td>
                </tr>
                <tr>
                  <td>{{ $t('packlist.totalCartons') }}</td>
                  <td>{{ totalCartons }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mb-3 mt-5" v-if="rows.length">
        <div class="col">
          <div class="table-white table-responsive mb-40">
            <table class="table table-stripped table-sm table-sc">
              <thead>
                <tr class="text-light">
                  <th scope="col" v-if="entity.hasPallets" rowspan="2">Pallet</th>
                  <th scope="col" colspan="2">{{ $t("packlist.masterCarton") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.innerCarton") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.workOrder") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.style") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.color") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.cup") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.size") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.innerPerCtn") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.itemTotalPerMc") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.masterQty") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.gwperctnkg") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.nwperctnkg") }}</th>
                  <th scope="col" rowspan="2">{{ $t("packlist.lwh") }}</th>
                </tr>
                <tr class="text-light">
                  <th>{{ $t("packlist.from") }}</th>
                  <th>{{ $t("packlist.to") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in rows">
                  <td :rowSpan="row.rowsInPallet" v-show="!row.skipMasterCarton && entity.hasPallets">
                    {{ row.palletID }}
                  </td>

                  <td :rowSpan="row.totalRowsInMaster" v-show="!row.skipInnerCarton">
                    {{ row.from }}
                  </td>
                  <td :rowSpan="row.totalRowsInMaster" v-show="!row.skipInnerCarton">
                    {{ row.to }}
                    <!-- row.masterNumberWithinPallet + row.masterQuantity - 1 -->
                  </td>

                  <td :rowspan="row.skuCount || 1" v-show="!row.skipSku && !row.isComment && !row.isEmptyMaster">
                    <span v-if="!row.innerIsHidden">
                      {{ row.innerIdent }}
                    </span>
                    <span v-else>w/o</span>
                  </td>
                  <td class="td-comment" v-show="row.isComment" colspan="9">{{ row.comment }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ poiShippedQuantityExt?.get(row.purchaseOrderItemId)?.workOrder }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.sifpre }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.sifbar }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.tipkos }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.sifvel }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.quantity }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.masterQuantity * row.quantity }}</td>
                  <td :rowSpan="row.rowsInMaster" v-show="!row.skipInnerCarton && !row.isComment && !row.isEmptyMaster">{{ row.masterQuantity }}</td>
                  <td :rowSpan="row.rowsInMaster" v-show="!row.skipInnerCarton">{{ row.grossWeight }}</td>
                  <td :rowSpan="row.rowsInMaster" v-show="!row.skipInnerCarton">{{ row.netWeight }}</td>
                  <td :rowSpan="row.rowsInMaster" v-show="!row.skipInnerCarton">
                    {{ row.length }}⨯{{ row.width }}⨯{{ row.height }}
                  </td>
                </tr>
                <tr class="total">
                  <td class="first-total">{{ $t("packlist.total") }}</td>
                  <td :colspan="entity.hasPallets ? 9 : 8"></td>
                  <td>{{ summaryTotal.shipQty }}</td>
                  <td>{{ totalCartons }}</td>
                  <td>{{ totalGross }}</td>
                  <td>{{ totalNet }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <h3 class="print-break" v-if="summary.length">{{ $t('packlist.summary') }}</h3>
      <div class="row">
        <div class="col mb-10">
          <div class="alert alert-warning alert-box" role="alert" v-show="atLeastOneOutstandingIsNegative">
          {{ $t('packlist.atLeastOneOutstandingIsNegative') }}
          </div>
        </div>
        <div class="col mb-10" v-if="entity?.note">
          <div class="notes mt-3">
            <div class="card-body">
              <p class="card-text">
                {{ entity.note }}
              </p>
            </div>
          </div>
        </div>
        <div class="col mb-10">
          <div class="notes mt-3">
            <div class="card-body">
              <h5 class="card-title">{{ $t('packlist.purchaseOrders') }}</h5>
              <p class="card-text">
                <ul>
                  <li v-for="po in purchaseOrders">{{ po }}</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>

      <ShipmentSummary :summary="summary" :summaryTotal="summaryTotal" />

      <div class="row d-print-none">
        <div class="col">
          <button class="btn btn-print btn-lg me-3" type="button" @click="$window.print()">{{ $t("common.print") }}</button>
          <button class="btn btn-outline-primary btn-lg me-3" type="button" @click="$router.push({ name: 'Shipment', params: { id: entity.id } })">{{ $t("common.toShipment") }}</button>
        </div>
      </div>
    </div>
  </div>`,

  setup(props, { emit }) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()

    const partner = ref()

    const { summary, loadShipmentSummary, summaryTotal, atLeastOneOutstandingIsNegative } = useSummary()

    const {
      entity,
      rows,
      addPallet,
      addInnerCarton,
      addMasterCarton,
      _getPallet,
      _getInnerCarton,
      removeSku,
      flatMasterCartons,
      _getMasterCarton,
      loadShipmentAsync,
      saveShipmentAsync,
      purchaseOrders,
      _getSku,
      poiShippedQuantityExt,
      poiShippedQuantityHere,
    } = useShipment(instance, route, router)

    const totalCartons = computed(() =>
      entity.value.pallets.reduce(
        (acc, pallet) => acc + pallet.masterCartons.reduce((accmc, mc) => accmc + mc.quantity, 0),
        0
      )
    )

    const totalGross = computed(() =>
      entity.value.pallets.reduce(
        (acc, pallet) => acc + pallet.masterCartons.reduce((accmc, mc) => accmc + mc.grossWeight * mc.quantity, 0),
        0
      )
    )

    const totalNet = computed(() =>
      entity.value.pallets.reduce(
        (acc, pallet) => acc + pallet.masterCartons.reduce((accmc, mc) => accmc + mc.netWeight * mc.quantity, 0),
        0
      )
    )

    watch(
      () => route.params.id,
      async () => {
        await loadShipmentAsync()
        if (entity.value.status === "Printing") {
          await loadShipmentSummary(route.params.id)
        }
      }
    )

    onMounted(async () => {
      await loadShipmentAsync()
      console.log(entity.value.status)
      if (entity.value.status === "Printing") {
        await loadShipmentSummary(route.params.id)
      }
      partner.value = await getEntityAsync(instance, "Partner", "me?includeLogo=true")
    })

    return {
      entity,
      summary,
      summaryTotal,
      rows,
      partner,
      totalCartons,
      totalGross,
      totalNet,
      atLeastOneOutstandingIsNegative,
      purchaseOrders,
      poiShippedQuantityExt,
    }
  },
}
