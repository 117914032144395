import { setError, startLoading, stopLoading, setSuccess, clearNotifications, setWarning, isError } from "./components/Notification"
import { fetchAuthenticated } from './utils/fetchAuthenticated'
import { DATETIME_FORMAT, EMPTY_GUID, DATE_FORMAT } from './utils/constants'

export const listEntitiesAsync = async (instance, apiEntity, filter) => {
  let result = null
  if (isError()) {
    return result
  }
  if (!apiEntity || typeof apiEntity !== 'string') {
    console.error("apiEntity should be string")
    return result
  }
  startLoading(`listEntitiesAsync ${apiEntity}${filter}`)
  try {
    let url = `${AZURE_URI}/${apiEntity}`
    if (filter?.length && filter.startsWith("?")) {
      url += filter
    }
    const raw = await fetchAuthenticated(instance, url, "GET")
    if (!Array.isArray(raw.result)) {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading(`listEntitiesAsync ${apiEntity}${filter}`)
  return result
}

export const listPagedEntitesAsync = async (instance, apiEntity, paging, statuses, filter) => {
  let result = {
    entities: [],
    numberOfRecords: 0
  }
  if (isError()) {
    return result
  }
  if (!apiEntity || typeof apiEntity !== 'string') {
    console.error("apiEntity should be string")
    return result
  }
  if (typeof statuses !== 'string' || statuses.length < 1) {
    console.warn("no statuses")
    return result
  }
  if (typeof filter !== 'string') {
    console.error("filter should be string")
    return result
  }
  if (!paging.pageNum || !paging.itemsPerPage) {
    console.error("paging missing required.")
    return result
  }
  startLoading(`listPagedEntitesAsync ${apiEntity} ${statuses} ${filter}`)
  try {
    let url = `${AZURE_URI}/${apiEntity}`
    if (statuses?.length) {
      url += '/' + statuses
    }
    url += `?pageNum=${paging.pageNum}&pageSize=${paging.itemsPerPage}`
    url += filter
    const raw = await fetchAuthenticated(instance, url, "GET")
    if (!Array.isArray(raw.item2) && !raw.itemsPerPage) {
      throw raw
    }
    if (raw.itemsPerPage) {
      result.entities = raw.result
      result.numberOfRecords = raw.numberOfRecords
    } else {
      result.entities = raw.item2
      result.numberOfRecords = raw.item1
    }
  } catch (e) {
    setError(e)
  }
  stopLoading(`listPagedEntitesAsync ${apiEntity} ${statuses} ${filter}`)
  return result
}

export const getEntityAsync = async (instance, apiEntity, id, extraParams = "") => {
  let result = null
  if (isError()) {
    return result
  }
  if (!apiEntity || typeof apiEntity !== 'string') {
    console.error("apiEntity should be string")
    return result
  }
  startLoading(`getEntityAsync ${apiEntity}`)
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/${apiEntity}/${id}${extraParams}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
    if (!!result) {
      result.isNew = id === 'new'
    }
  } catch (e) {
    setError(e)
  }
  stopLoading(`getEntityAsync ${apiEntity}`)
  return result
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getMyPartnerAsync = async (instance, includeLogo = false) => {
  startLoading('getMyPartnerAsync')
  try {
    clearNotifications()
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Partner/me?includeLogo=${includeLogo}`, "GET")
    if (raw?.status !== "ok") {
      throw raw
    }
    stopLoading('getMyPartnerAsync')
    return raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading('getMyPartnerAsync')
  return null
}

export const saveEntityAsync = async (instance, apiEntity, entity, isNew = null) => {
  let result = null
  if (!apiEntity || typeof apiEntity !== 'string') {
    console.error("apiEntity should be string")
    return result
  }
  if (!entity || typeof entity !== 'object') {
    console.error("entity should be object")
    return result
  }
  startLoading(`saveEntityAsync ${apiEntity}`)
  try {
    const method = isNew === null ? (entity.isNew ? "POST" : "PUT") : (isNew ? "POST" : "PUT")
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/${apiEntity}`, method, entity)
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading(`saveEntityAsync ${apiEntity}`)
  return result
}

export const deleteEntityAsync = async (instance, apiEntity) => {
  let result = null
  if (!apiEntity || typeof apiEntity !== 'string') {
    console.error("apiEntity should be string")
    return result
  }
  startLoading(`deleteEntityAsync ${apiEntity}`)
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/${apiEntity}`, "DELETE")
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading(`deleteEntityAsync ${apiEntity}`)
  return result
}

export const patchEntityAsync = async (instance, apiEntity, entity) => {
  let result = null
  if (!apiEntity || typeof apiEntity !== 'string') {
    console.error("apiEntity should be string")
    return result
  }
  startLoading(`patchEntityAsync ${apiEntity}`)
  try {
    const raw = await fetchAuthenticated(instance, `${AZURE_URI}/${apiEntity}`, "PATCH", entity)
    if (raw?.status !== "ok") {
      throw raw
    }
    result = raw.result
  } catch (e) {
    setError(e)
  }
  stopLoading(`patchEntityAsync ${apiEntity}`)
  return result
}

