import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onUnmounted } from 'vue'
import { useShipment } from "../composition-api/useShipment"
import { useMsal } from '../composition-api/useMsal'

const styleA5 = document.createElement('style')
const style14x10 = document.createElement('style')
const style65x45 = document.createElement('style')
styleA5.setAttribute("id", "a5")
styleA5.textContent = `
  @media print{
    @page {
        size: a5 landscape;
        margin: 0.5cm !important;
        padding: 0.5cm 0 !important;
    }
  }
`
style14x10.setAttribute("id", "14x10")
style14x10.textContent = `
  @media print{
    @page {
        size: 14cm 10cm;
        margin: 0 !important;
        padding: 0 !important;
    }
  }
`
style65x45.setAttribute("id", "65x45")
style65x45.textContent = `
  @media print{
    @page {
        size: 6.5cm 4.5cm;
        margin: 0 !important;
        padding: 0 !important;
    }
  }
`

export default Labels = {
  template: `
  <div class="labels">
    <div class="container">
      <h3>{{ $t('shipment.labels') }}</h3>

      <div class="row mb-5">
        <div class="col print-select">
          <label class="mr-4">Select Print Options</label>
          <select v-model="bodyCss">
            <option value="none" disabled selected>Select your option</option>
            <option value="print-a5">A5</option>
            <option value="print-14x10">14 x 10 cm</option>
            <option value="print-6-5x4-5">6.5 x 4.5 cm</option>
          </select>
        </div>
      </div>

      <div class="row mb-5" v-if="extendedBoxes?.length">
        <div class="col-lg-6 mb-2" v-for="box in extendedBoxes">
          <div class="card card-barcode">
            <div class="card-body">
              <div class="card-barcode-body">
                <img src="/images/lisca.webp" class="lisca-logo" alt="">
                <h5 class="card-title"><span>Box</span>
                <span class="text-nowrap">{{ box.boxPrint }}</span></h5>

                <div class="row">
                  <div class="col-12 col-md-6">
                    <ul>
                      <li><span>Style:</span> {{ box.sifpre }}</li>
                      <li><span>Color:</span> {{ box.sifbar }}</li>
                      <li><span>Cup:</span> {{ box.tipkos }}</li>
                      <li><span>Size:</span> {{ box.sifvel }}</li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-6">
                    <ul>
                      <li><span>Quantity:</span> {{ box.quantity }}</li>
                      <li><span>Gross Weight:</span> {{ box.grossWeight }}</li>
                      <li><span>Net Weight:</span> {{ box.netWeight }}</li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 print-comment">{{ box.comment }}</div>
                </div>
              </div>

              <div class="barcodes">
                <p class="card-text barcode">
                  <span class="barcode barcode-first">{{ $filters.encode128(box.barCode) }}</span>
                  <span class="barcode-nmr">{{ box.barCode }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class='page-break'></div>
        </div>
      </div>

      <div v-else class="row d-print-none d-print-none-box">
        Ni nalepk
      </div>
    </div>

    <div class="labels-footer">
      <div class="container">
        <div class="row d-print-none">
          <div class="col">
            <button class="btn btn-print me-3" type="button" @click="$window.print()">{{ $t("common.print") }}</button>
            <button class="btn btn-outline-primary btn-lg me-3" type="button" @click="$router.push({ name: 'Shipment', params: { id: entity.id } })">{{ $t("common.toShipment") }}</button>
          </div>
        </div>
      </div>
    </div>

  </div>
  `,
  setup (props, { emit }) {
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const bodyCss = ref('none')

    const { entity, rows, extendedBoxes, addPallet, addInnerCarton, addMasterCarton, _getPallet, _getInnerCarton, removeSku, flatMasterCartons, _getMasterCarton, loadShipmentAsync, saveShipmentAsync, purchaseOrders, _getSku, poiShippedQuantityExt, poiShippedQuantityHere } = useShipment(instance, route, router)

    const removeAllPrintStyles = () => {
      if (document.getElementById('a5')) document.head.removeChild(styleA5)
      if (document.getElementById('14x10')) document.head.removeChild(style14x10)
      if (document.getElementById('65x45')) document.head.removeChild(style65x45)
    }

    watch(() => bodyCss.value, (next, curr) => {
      removeAllPrintStyles()
      if (curr !== 'none') {
        document.body.classList.remove(curr)
      }
      if (next === 'print-a5') document.head.appendChild(styleA5)
      if (next === 'print-14x10') document.head.appendChild(style14x10)
      if (next === 'print-6-5x4-5') document.head.appendChild(style65x45)
      document.body.classList.add(next)
    })

    onUnmounted (() => {
      removeAllPrintStyles()
      document.body.classList.remove(...document.body.classList);
    })

    watch(() => route.params.id, async () => {
      await loadShipmentAsync()
    })

    onMounted(async () => {
      await loadShipmentAsync()
    })

    return { entity, extendedBoxes, bodyCss  }
  }
}


