import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'

const OrderBadge = {
  name: 'OrderBadge',
  template: `
  <span class="badge bg-danger" :class="cssClass" v-if="status === 'Deleted'">{{ $t(status) }}</span>
  <span class="badge bg-primary" :class="cssClass" v-else-if="status === 'Preparing' || status === 'Open'">{{ $t(status) }}</span>
  <span class="badge bg-info text-dark" :class="cssClass" v-else-if="status === 'InProgress'">{{ $t(status) }}</span>
  <span class="badge bg-warning" :class="cssClass" v-else-if="status === 'Printing'">{{ $t(status) }}</span>
  <span class="badge bg-success" :class="cssClass" v-else-if="status === 'Shipped'">{{ $t(status) }}</span>
  <span class="badge bg-secondary" :class="cssClass" v-else>{{ $t(status) }}</span>

  <span :class="cssClass"><small>{{ (dateCompleted && !dateCompleted.startsWith('0001')) ? $dayjs(dateCompleted).format('&nbsp; D MMM YY') : '' }}</small></span>
`,
  props: {
    cssClass: String,
    status: String,
    dateCompleted: String
  },
  setup(props) {
    return {}
  }
}

export default OrderBadge
