import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'
import { getEntityAsync, getMyPartnerAsync, listEntitiesAsync } from '../api'
import { startLoading, stopLoading, setError, setSuccess } from '../components/Notification'
import { fetchAuthenticated } from '../utils/fetchAuthenticated'
import { useMsal } from '../composition-api/useMsal'

export const Partner = {
  template: `
  <div class="top-container">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="bg-lisca" v-if="partner">
            <h5>{{ partner.name }}</h5>
            <p>{{ partner.address }}</p>
            <h6>{{ partner.postCode }} {{ partner.country }}</h6>
          </div>
        </div>
        <div class="col-12 col-md-8" v-if="partner?.imageBase64">
          <img class="img-fluid" :src="'data:image/jpeg;base64, ' + partner.imageBase64"  />
        </div>
      </div>
    </div>
  </div>

  <div class="container">

    <div class="alert alert-warning" role="alert" v-show="message">
      {{ message }}
    </div>

    <div class="row">
      <div class="col pt-4 pb-2">
        <input ref="fileEl" v-on:change="validateFiles" type="file" /> <!-- v-on:change="handleFileUpload()" accept="image/*;capture=camera" -->
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <button class="btn btn-success me-3" type="button" @click="uploadFileAsync" :disabled="!hasFiles">{{ $t("common.upload") }}</button>
      </div>
    </div>

  </div>
  `,
  setup() {
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const fileEl = ref(null)
    const hasFiles = ref(false)
    const message = ref('')
    const partner = ref()

    const ALLOWED_TYPES = 'image/jpeg'

    const validateFiles = () => {
      message.value = ""
      for (let i = 0; i < fileEl.value.files.length; i++) {
        if (!fileEl.value.files.item(i).type.startsWith(ALLOWED_TYPES)) {
          message.value = "Napaka pri nalaganju datoteke. Datoteka ni tipa " + ALLOWED_TYPES
          return
        }
      }
      hasFiles.value = true
    }

    const uploadFileAsync = async f => {
      // for (let i = 0; i < fileEl.value.files.length; i++) {
      //   const f = fileEl.value.files.item(i)
      //   uploadFileAsync(f)
      // }

      startLoading("uploadFileAsync")
      try {
        const form = new FormData()
        const f = fileEl.value.files.item(0)
        if (!f) {
          setError("No file")
          return
        }
        form.append('file', f)
        const raw = await fetchAuthenticated(instance, `${AZURE_URI}/Partner/${partner.value.id}`, "POST", form)
        if (raw.status !== "ok") {
          setError(raw)
        } else {
          setSuccess("Saved.")
          await loadPartnerAsync()
        }
      } catch (e) {
        setError(e)
      }
      stopLoading("uploadFileAsync")
    }

    const loadPartnerAsync = async () => {
      partner.value = await getMyPartnerAsync(instance, true)
    }

    onMounted(async () => {
      await loadPartnerAsync()
    })

    return { partner, uploadFileAsync, message, fileEl, hasFiles, validateFiles }
  }
}
