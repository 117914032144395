/* global VueRouter */
import { createApp } from 'vue'
import App from './App.js'

import { msalInstance } from "./authConfig.js"
import { msalPlugin } from "./plugins/msalPlugin";
import { CustomNavigationClient } from "./router/NavigationClient"

import { Paging } from "./components/Paging"
import { Modal } from "./components/Modal"
import { SelectEntity }  from "./components/SelectEntity"
import { MasterCartonModal } from "./components/MasterCartonModal"
import OrderBadge  from "./components/OrderBadge"

import router from './router/router'
import { setupI18n, loadLocaleMessages } from './i18n.js'

const RequiredWarning = {
  name: 'RequiredWarning',
  template: `
  <div class="alert alert-warning" role="alert">
    Izpolnite obvezne podatke!
  </div>
`}

// The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
const navigationClient = new CustomNavigationClient(router)
msalInstance.setNavigationClient(navigationClient)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts()
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0])
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === msal.EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload // AuthenticationResult
    const account = payload.account
    msalInstance.setActiveAccount(account)
  }
})

const i18n = setupI18n({
  legacy: false,
  locale: 'en',
  // fallbackLocale: 'en',
})

const app = createApp(App)
app.component('RequiredWarning', RequiredWarning)
app.component('Paging', Paging)
app.component('Modal', Modal)
app.component('OrderBadge', OrderBadge)
app.component('MasterCartonModal', MasterCartonModal)
app.component('Autocomplete', window['vue3-autocomplete'])
app.component('SelectEntity', SelectEntity)
app.config.globalProperties.$filters = {
  encode128(value) {
    const _encoder = new Code128Generator()
    return _encoder.encode(value)
  }
}
app.config.globalProperties.$window = window
app.config.globalProperties.$log = console.log
app.config.globalProperties.$EMPTY_GUID = "00000000-0000-0000-0000-000000000000"
app.config.globalProperties.$AZURE_URI = AZURE_URI
app.use(i18n)
app.use(router)
app.use(msalPlugin, msalInstance)

Promise.all([
  loadLocaleMessages(i18n, 'en'),
  loadLocaleMessages(i18n, 'sr')
])
.then(() => {
  router.isReady().then(() => {
    // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
    app.mount('#app')
  })
})
.catch((error) => {
  console.error(error)
});
