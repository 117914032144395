import { readonly, ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { useMsal } from "./useMsal"

export function useIsAuthorized() {
  const { instance, accounts } = useMsal()

  const displayName = computed(() => {
    if (accounts.value.length > 0) {
      return accounts.value[0].idTokenClaims?.name
    }
    return ""
  })

  const idTokenClaims = computed(() => {
    if (accounts.value.length > 0) {
      return accounts.value[0].idTokenClaims
    }
    return null
  })

  const isAuthorized = computed(() => {
    if (accounts.value.length > 0) {
      return accounts.value[0].idTokenClaims?.extension_PartnerId?.length > 0
    }
    return false
  })

  const isAdmin = computed(() => {
    if (accounts.value.length > 0) {
      return accounts.value[0].idTokenClaims?.extension_Role === "admin"
    }
    return false
  })

  const partnerId = computed(() => {
    if (accounts.value.length > 0) {
      return accounts.value[0].idTokenClaims?.extension_PartnerId
    }
    return null
  })

  return {
    displayName,
    idTokenClaims,
    isAdmin,
    isAuthorized,
    partnerId,
  }
}
