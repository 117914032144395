import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from "vue"
import { getEntityAsync, getMyPartnerAsync, listEntitiesAsync } from "../api"
import { useMsal } from "../composition-api/useMsal"

export const Admin = {
  template: `<div class="top-container">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <label class="col-form-label col-sm-3">PO status</label>
          <div class="col-sm-9">
            <select class="form-control" v-model="poStatus">
              <option value="Open">Open</option>
              <option value="Closed">Closed</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <label class="col-form-label col-sm-3">Shipment status</label>
          <div class="col-sm-9">
            <select class="form-control" v-model="shipmentStatus">
              <option value="">All</option>
              <option value="Preparing">Preparing</option>
              <option value="Printing">Printing</option>
              <option value="Shipped">Shipped</option>
              <option value="Done">Done</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h3 class="mt-34">{{ $t('home.purchaseOrders') }}</h3>
        <table class="table table-stripped table-sm table-responsive table-rw" v-if="purchaseOrders?.length">
          <thead class="thead-light">
            <tr>
              <th scope="col">{{ $t('admin.PartnerName') }}</th>
              <th scope="col">{{ $t('home.PO') }}</th>
              <th scope="col">{{ $t('home.skuNumber') }}</th>
              <th scope="col">{{ $t('home.totalQuantity') }}</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="po in purchaseOrders">
                <td>{{ po.partnerName }}</td>
                <td><strong>{{ po.po }}</strong></td>
                <td>{{ po.lineItemsCount }}</td>
                <td>{{ po.lineItemsQuantity }}</td>
              </tr>
          </tbody>
        </table>
        <div class="col justify-content-center" v-else>
          <p>{{ $t('home.noPoFound') }}</p>
        </div>
      </div>
      <div class="col">
        <h3 class="mt-34">{{ $t('home.shipments') }}</h3>
        <table class="table table-stripped table-sm table-responsive table-rw" v-if="shipments?.length">
          <thead class="thead-light">
            <tr>
              <th scope="col">{{ $t('admin.PartnerName') }}</th>
              <th scope="col">{{ $t('home.shipmentNumber') }}</th>
              <th scope="col">{{ $t('home.shipmentType') }}</th>
              <th scope="col">{{ $t('home.shipmentStatus') }}</th>
              <th scope="col">{{ $t('home.shipmentDate') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr role="button" v-for="s in shipments" @click="select(s)">
              <td>{{ s.partnerName }}</td>
              <td><strong>{{ s.shipmentNumber }}</strong></td>
              <td>{{ s.shipmentType }} </td>
              <td><OrderBadge :status="s.status" /></td>
              <td>{{ s.date }}</td>
            </tr>
          </tbody>
        </table>
        <div class="col justify-content-center" v-else>
          <p>{{ $t('home.noShipmentsFound') }}</p>
        </div>
      </div>
    </div>
  </div>
</div>`,
  setup(props, { emit }) {
    const router = VueRouter.useRouter()
    const { instance } = useMsal()

    const purchaseOrders = ref()
    const shipments = ref()
    const poStatus = ref("Open")
    const shipmentStatus = ref("Preparing")

    const select = (entity) => {
      const routeData = router.resolve({ name: "Shipment", params: { id: entity.id } })
      router.push(routeData)
    }

    const loadAsync = async () => {
      purchaseOrders.value = await listEntitiesAsync(instance, "PurchaseOrders/" + poStatus.value + "?adminView=true")
      shipments.value = await listEntitiesAsync(instance, "Shipments/" + shipmentStatus.value + "?adminView=true")
    }

    watch(poStatus, loadAsync)
    watch(shipmentStatus, loadAsync)

    onMounted(loadAsync)

    return { purchaseOrders, shipments, poStatus, shipmentStatus, select }
  },
}
