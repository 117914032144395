import { patchEntityAsync } from "../api"
import { setError, setSuccess } from '../components/Notification'
import { useMsal } from '../composition-api/useMsal'

export const TakeOwnershipModal = {
  template: `<Modal :visible="!!masterCarton" @close="$emit('close')" :title="$t('shipment.changeMasterCartonOwnership')">
  <div class="row">
    <div class="col">
      <div class="row mt-3">
        <div class="col-sm-4">{{ $t('common.created') }}</div>
        <div class="col-sm-8">{{ masterCarton.userCreatedID }} <strong>{{ masterCarton.dateCreated }}</strong></div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="row mt-3">
        <div class="col-sm-4">{{ $t('common.modified') }}</div>
        <div class="col-sm-8">{{ masterCarton.userModifiedID }} <strong>{{ masterCarton.dateModified }}</strong></div>
      </div>
    </div>
  </div>
  <template v-slot:buttons>
    <button class="btn btn-danger me-2 ms-2" type="button" @click="takeMasterCartonOwnershipAsync">{{ $t('common.takeOwnership') }}</button>
  </template>
</Modal>`,
  props: {
    masterCarton: {
      type: Object,
      default: null
    },
    shipmentId: { type: String, default: null }
  },
  emits: [ 'close', 'success'],
  setup(props, { emit }) {
    const { instance } = useMsal()

    const takeMasterCartonOwnershipAsync = async () => {
      console.log(props.masterCarton)
      // const masterCarton = _getMasterCarton(row.palletID, row.masterIdent)
      console.log(props.shipmentId)
      if(!props.masterCarton || !props.masterCarton.id || props.masterCarton.isNew || !props.shipmentId) {
        setError("Napaka pri prevzemanju.")
        return
      }
      const result = await patchEntityAsync(instance, `MasterCarton/${props.shipmentId}`, props.masterCarton)
      if (result && result.id && result.canChange) {
        setSuccess("Prevzeto.")
        emit('success', result)
      } else {
        setError("Napaka pri prevzemanju.")
      }
      emit('close')
    }

    return {
        takeMasterCartonOwnershipAsync
    }
  }

}
