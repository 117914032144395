import { toRefs, getCurrentInstance } from 'vue'

export function useMsal() {
    const internalInstance = getCurrentInstance()
    if (!internalInstance) {
      throw "useMsal() cannot be called outside the setup() function of a component"
    }
    const { instance, accounts, inProgress} = toRefs(internalInstance.appContext.config.globalProperties.$msal)

    if (!instance || !accounts || !inProgress) {
      throw "Please install the msalPlugin"
    }

    if (inProgress.value === msal.InteractionStatus.Startup) {
      instance.value.handleRedirectPromise()
      // .then(resp => {
      //   console.log(resp)
      //   if (resp && resp.accessToken && resp.account) {
      //     console.warn(resp.idTokenClaims)
      //     console.warn(resp.homeAccountId)
      //     console.warn(resp.accessToken)
      //   }
      // })
      .catch(() => {
        // Errors should be handled by listening to the LOGIN_FAILURE event
        return
      })
    }

    return {
      instance: instance.value,
      accounts,
      inProgress
    }
}
