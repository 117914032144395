/* global VueRouter */
import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject } from 'vue'


import { useMsal } from '../composition-api/useMsal'
import { loginRequest } from "../authConfig"
import { useIsAuthenticated } from "../composition-api/useIsAuthenticated"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

const SignInOutButton = {
  name: 'SignInOutButton',
  template: `


  <button v-if="!isAuthenticated" class="btn-login" type="button" @click="loginRedirect">{{ $t('signInOut.login') }}</button>
  <button v-else class="btn-logout" type="button" @click="logoutRedirect">{{ $t('signInOut.logout') }}</button>
  `,
  setup (props, { emit }) {
    const { instance } = useMsal()
    const { worker } = useIsAuthorized()
    const isAuthenticated = useIsAuthenticated()

    const loginRedirect = () => instance.loginRedirect(loginRequest)

    const logoutRedirect = () => instance.logoutRedirect()

    return { loginRedirect, logoutRedirect, isAuthenticated, worker }
  },
}

export default SignInOutButton
