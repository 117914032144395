import Home from "../views/Home.js"
import Shipment from "../views/Shipment.js"
import Packlist from "../views/Packlist.js"
import { Partner } from "../views/Partner.js"
import { Admin } from "../views/Admin.js"
import Labels from "../views/Labels.js"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

const routes = [
  {
    path: "/:keepMessages?",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/shipment/:id/:keepMessages?",
    name: "Shipment",
    component: Shipment,
    meta: { requiresAuth: true },
  },
  {
    path: "/shipment/:id/packlist/:keepMessages?",
    name: "Packlist",
    component: Packlist,
    meta: { requiresAuth: true },
  },
  {
    path: "/shipment/:id/labels/:keepMessages?",
    name: "Labels",
    component: Labels,
    meta: { requiresAuth: true },
  },
  {
    path: "/partner",
    name: "Partner",
    component: Partner,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { requiresAuth: true },
  },
  {
    path: "/test",
    name: "Test",
    component: {
      template: '<button class="btn btn-warning" @click="test">Test error.</button><pre>{{ idTokenClaims }}</pre>',
      setup() {
        const { idTokenClaims } = useIsAuthorized()
        const test = () => {
          throw "Error"
        }
        return { test, idTokenClaims }
      },
    },
  },
]

const router = VueRouter.createRouter({
  history: window.location.href.includes("localhost") ? VueRouter.createWebHashHistory() : VueRouter.createWebHistory(),
  routes,
})

// registerGuard(router)

export default router
