import { ref, toRef, toRefs, computed, defineComponent, watch, reactive, onMounted, inject, onUnmounted } from "vue"
import { patchEntityAsync, saveEntityAsync, deleteEntityAsync } from "../api"
import { useEnums } from "../composition-api/useEnums"
import { useMsal } from "../composition-api/useMsal"
import ShipmentSummary from "../components/ShipmentSummary"
import { useSummary } from "../composition-api/useSummary"
import { SelectPurchaseOrderItem } from "../components/SelectPurchaseOrderItem"
import { useShipment } from "../composition-api/useShipment"
import { TakeOwnershipModal } from "../components/TakeOwnershipModal"
import { setError, setSuccess } from "../components/Notification"
import { useIsAuthorized } from "../composition-api/useIsAuthorized"

Array.prototype.unique = function () {
  var a = this.concat()
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1)
    }
  }
  return a
}

export default Shipment = {
  name: "Shipment",
  components: { SelectPurchaseOrderItem, ShipmentSummary, TakeOwnershipModal },
  template: `
  <div v-if="entity">

    <div class="top-container">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-8">
            <div class="shipment-head">
              <div class="row">
                <div class="col-6 col-sm-4" v-if="entity.isNew">{{ $t('shipment.isNew') }}</div>
                <div class="col-6 col-sm-4" v-else><h1>{{ entity.shipmentNumber }}</h1><p>{{ $t('shipment.inYear') }} {{ entity.shipmentYear }} {{ $t('shipment.is') }} {{ entity.partnerShipmentNumber }} {{ $t('shipment.inYearDocument') }}</p></div>
                <div class="col-6 col-sm-8 d-flex justify-content-between"><h3 class="d-none d-lg-block">{{ $t('shipment.shipment') }}</h3><span><OrderBadge :status="entity.status" /></span></div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-8">

            <div class="row mt-3">
              <label class="col-form-label col-sm-4">{{ $t('shipment.type') }}</label>
              <div class="col-sm-8">
                <select class="form-select" v-model="entity.shipmentType" :disabled="!entity.canChange || !entity.canSave">
                  <option value=""></option>
                  <option v-for="[key, value] in shipmentTypes" :value="key">{{ value }}</option>
                </select>
              </div>
            </div>

            <div class="row mt-3">
              <label class="col-form-label col-sm-4">{{ $t('shipment.date') }}</label>
              <div class="col-sm-8">
                <input class="form-control" type="date" v-model="entity.date" :disabled="!entity.canChange || !entity.canSave" />
              </div>
            </div>

            <div class="row mt-3">
              <label class="col-form-label col-sm-4">{{ $t('shipment.billOfLanding') }}</label>
              <div class="col-sm-8">
                <input class="form-control" v-model="entity.billOfLanding" :disabled="!entity.canChange || !entity.canSave" />
              </div>
            </div>
            <div class="row mt-3">
              <label class="col-form-label col-sm-4">{{ $t('shipment.comInvoiceNo') }}</label>
              <div class="col-sm-8">
                <input class="form-control" v-model="entity.comInvoiceNo" :disabled="!entity.canChange || !entity.canSave" />
              </div>
            </div>
            <div class="row mt-3">
              <label class="col-form-label col-sm-4">{{ $t('shipment.shippedVia') }}</label>
              <div class="col-sm-8">
                <input class="form-control" v-model="entity.shippedVia" :disabled="!entity.canChange || !entity.canSave" />
              </div>
            </div>

            <div class="row mt-3">
              <label class="col-form-label col-sm-4">{{ $t('shipment.note') }}</label>
              <div class="col-sm-8">
                <textarea class="form-control" v-model="entity.note" rows="3" :disabled="!entity.canChange || !entity.canSave" />
              </div>
            </div>

            <div class="row mt-3">
              <label class="form-check-label col-sm-4" for="has-pallets">{{ $t('shipment.hasPallets') }}</label>
              <div class="col-sm-8">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="has-pallets" v-model="entity.hasPallets" :disabled="rows.length || !entity.canChange" />
                  {{ $t('shipment.cantChangeAfterMasterCarton') }}
                </div>
              </div>
            </div>

          </div>
          <div class="col">
            <div class="card mb-3 mt-4 mt-lg-0" v-show="purchaseOrders?.length || defaultDimensions.length">
              <div class="card-body">
                <p class="card-text">
                {{ $t('shipment.defaultDimensions') }} {{ defaultDimensions.length }} x {{ defaultDimensions.width }} x {{ defaultDimensions.height }} cm
                </p>
                <p class="card-text">
                  <ul>
                    <li v-for="po in purchaseOrders">{{ po }}</li>
                  </ul>
                </p>
              </div>
            </div>

            <div class="card mb-3 mt-4 mt-lg-0" v-show="!entity.isNew">
              <div class="card-body">
                <p class="card-text" style="font-size: 0.8em">{{ entity.userCreatedID }}<br/><strong>{{ entity.dateCreated }}</strong>
                <span v-if="entity.userModifiedID !== $EMPTY_GUID"><br/><br/>{{ entity.userModifiedID }}<br/><strong>{{ entity.dateModified }}</strong></span></p>
                <p class="card-text" v-if="!entity.canSave">
                  <button class="btn btn-sm mb-1 btn-outline-warning" @click="takeShipmentOwnership(entity)" v-show="partnerId === entity.partnerId">{{ $t("common.takeOwnership") }} 🗝️</button>
                </p>
              </div>
            </div>

          </div>
        </div>

        <div class="row mt-5">
          <div class="alert alert-warning" role="alert" v-show="anyRowIsQuantityZero">{{ $t('shipment.anyRowIsQuantityZero') }}</div>

          <div class="alert alert-warning" role="alert" v-show="!isValidForPrinting">{{ $t('shipment.missingRequiredData') }}</div>
        </div>

        <div class="row mt-md-5" v-show="partnerId === entity.partnerId || entity.isNew">
          <div class="col-12 d-flex flex-column flex-lg-row justify-content-end ship-btns">
            <div class="me-2">
              <p v-show="entity.canChange">{{ $t('shipment.savingOnlyMetadata') }}</p>
              <button class="btn btn-link btn-link-barcode" type="button" @click="$router.push({ name: 'Labels', params: { id: entity.id } })" v-show="entity.status !== 'Preparing'">{{ $t('shipment.labels') }}</button>
              <button class="btn btn-link btn-link-packing" type="button" @click="$router.push({ name: 'Packlist', params: { id: entity.id } })" v-show="entity.status !== 'Preparing'">{{ $t('shipment.packlist') }}</button>
            </div>
            <div class="d-flex d-lg-block flex-column flex-lg-row">
              <button class="btn btn-cancel me-lg-3" type="button" @click="$router.push({ name: 'Home' })">{{ $t('common.cancel') }}</button>
              <button class="btn btn-mark-outline me-lg-3" type="button" @click="markAsAsync('Preparing')" v-show="!entity.isNew && entity.status === 'Printing'">{{ $t('shipment.markAsPreparing') }}</button>
              <button class="btn btn-mark btn-lg me-lg-3" type="button"
                @click="markAsAsync('Printing')"
                v-show="!entity.isNew && entity.status !== 'Printing' && entity.status !== 'Shipped'"
                :disabled="anyRowIsQuantityZero || !isValidForPrinting || !entity.canSave">
                {{ $t('shipment.markAsPrintingAsync') }}
              </button>
              <button class="btn btn-mark btn-lg me-lg-3" type="button"
                @click="markAsAsync('Shipped')"
                v-show="!entity.isNew && entity.status === 'Printing' && entity.status !== 'Shipped'"
                :disabled="anyRowIsQuantityZero || !isValidForPrinting  || !entity.canSave">
                  {{ $t('shipment.markAsShippedAsync') }}
              </button>
              <button class="btn btn-save btn-lg" type="button"
                @click="localSaveEntityAsync"
                v-show="entity.status !== 'Shipped'"
                :disabled="!entity.canSave || !entity.canChange">
                {{ $t('common.save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sticky">
      <div class="row" v-if="!entity.isNew && entity.canChange && partnerId === entity.partnerId">
        <div class="col text-end">
          <button class="btn btn-outline-primary" type="button" @click="addPallet(true)" v-if="entity.hasPallets" :disabled="!entity.canChange || hasUnsavedMasterCarton">{{ $t('shipment.addPallet') }}</button>
          <button class="btn btn-primary" type="button" @click="addPallet(true)" v-else-if="rows.length === 0"  :disabled="!entity.canChange || hasUnsavedMasterCarton">{{ $t('shipment.startEntry') }}</button>
          <button class="btn btn-outline-primary" type="button" @click="addMasterCarton('P-1')" v-else  :disabled="!entity.canChange || hasUnsavedMasterCarton">{{ $t('shipment.addMasterCarton') }}</button>
        </div>
      </div>
    </div>

    <div class="container">

      <div class="row mt-4" v-if="rows.length">
        <div class="col">
          <div class="table-white table-responsive">
            <table class="table table-stripped table-sm">
              <thead>
                <tr>
                  <th scope="col" v-if="entity.hasPallets">{{ $t('shipment.pallet') }}</th>
                  <th scope="col">{{ $t('shipment.masterCarton') }}</th>
                  <th scope="col">{{ $t('shipment.innerCarton') }}</th>
                  <th scope="col">{{ $t('shipment.PO') }}</th>
                  <th scope="col">{{ $t('shipment.style') }}</th>
                  <th scope="col">{{ $t('shipment.color') }}</th>
                  <th scope="col">{{ $t('shipment.cup') }}</th>
                  <th scope="col">{{ $t('shipment.size') }}</th>
                  <th scope="col">{{ $t('shipment.POqty') }}</th>
                  <th scope="col">{{ $t('shipment.qty') }}</th>
                  <th scope="col">{{ $t('shipment.poQtyMinusShipped') }}</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in rows" :class="{ 'bg-light': row.isNew }" class="td-vc">
                  <td :rowSpan="row.rowsInPallet" v-show="!row.skipMasterCarton && entity.hasPallets">
                    <button class="btn btn-sm btn-outline-primary"
                      :disabled="hasUnsavedMasterCarton"
                      @click="addMasterCarton(row.palletID)"
                      v-show="entity.canChange">
                      + master
                    </button>
                  </td>
                  <td :rowSpan="row.totalRowsInMaster" v-show="!row.skipInnerCarton">
                    <button class="btn btn-sm mb-4"
                      v-show="row.masterIdent"
                      :disabled="!row.masterCanChange || !entity.canChange"
                      :class="{
                      'btn-secondary': row.grossWeight && row.netWeight,
                      'btn-warning': !(row.grossWeight && row.netWeight) }"
                      @click="showMasterCartonEditor(row)">{{ row.masterQuantity }} ⨯ {{ row.masterIdent }} 📝 </button><br>
                    <button class="btn btn-sm btn-outline-primary mb-1"
                      v-show="row.masterIdent && entity.canChange && row.masterCanChange"
                      @click="addInnerCarton(row.palletID, row.masterIdent)">+ inner</button><br>
                    <button class="btn btn-sm btn-primary" v-show="row.masterIdent && entity.canChange && row.masterCanChange" @click="showSelectPurchaseOrderItem(row, true)">+ sku</button>
                  </td>
                  <td :rowspan="row.skuCount || 1" v-show="!row.skipSku && !row.isComment && !row.isEmptyMaster">
                    <span v-show="!row.innerIsHidden">
                      <button class="btn btn-sm btn-primary" v-show="row.innerIdent && entity.canChange && row.masterCanChange" @click="showSelectPurchaseOrderItem(row)">+ sku</button>
                    </span>
                  </td>
                  <td class="td-comment" v-show="row.isComment" colspan="10">
                    <div class="mt-1 p-3 bg-light rounded">{{ row.comment }}</div>
                  </td>
                  <td v-show="row.isEmptyMaster" colspan="10">{{ $t('common.empty') }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.po }}<br> {{ poiShippedQuantityExt?.get(row.purchaseOrderItemId)?.workOrder }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.sifpre }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.sifbar }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.tipkos }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">{{ row.sifvel }}</td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">
                    <button type="button" class="btn btn-sm position-relative btn-secondary" v-if="row.orderQuantity">
                      {{ row.orderQuantity }}
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success" v-if="poiShippedQuantityExt?.get(row.purchaseOrderItemId)">
                        {{ poiShippedQuantityExt?.get(row.purchaseOrderItemId)?.shipped }}
                      </span>
                    </button>
                  </td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">
                    <span v-show="row.po">
                      <span v-show="row.masterQuantity > 1" class="badge bg-info me-2 bad-m-full">{{ row.masterQuantity }} ⨯</span>
                      <input type="number" class="form-control" :disabled="!entity.canChange || !row.masterCanChange" :value="row.quantity" @input="e => updateShipmentQuantity(e, row)" style="width: 70px; display: inline" required min="1">
                    </span>
                  </td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">
                    <button type="button" class="btn btn-sm position-relative"
                      :class="{
                        'btn-danger': row.orderQuantity - poiShippedQuantityExt?.get(row.purchaseOrderItemId)?.shipped - row.masterQuantity * poiShippedQuantityHere?.get(row.purchaseOrderItemId) < 0,
                        'btn-success': row.orderQuantity - poiShippedQuantityExt?.get(row.purchaseOrderItemId)?.shipped - row.masterQuantity * poiShippedQuantityHere?.get(row.purchaseOrderItemId) === 0,
                        'btn-warning': row.orderQuantity - poiShippedQuantityExt?.get(row.purchaseOrderItemId)?.shipped - row.masterQuantity * poiShippedQuantityHere?.get(row.purchaseOrderItemId) > 0,
                      }">{{ -(row.orderQuantity - poiShippedQuantityExt?.get(row.purchaseOrderItemId)?.shipped - row.masterQuantity * poiShippedQuantityHere?.get(row.purchaseOrderItemId)) }}
                    </button>
                  </td>
                  <td v-show="!row.isComment && !row.isEmptyMaster">
                    <button class="btn btn-sm-delete" v-show="row.po && entity.canChange && row.masterCanChange" @click="removeSku(row)">❌</button>
                  </td>
                  <td :rowSpan="row.totalRowsInMaster" v-show="!row.skipInnerCarton">
                    <button class="btn btn-sm mb-1 btn-save d-block"
                      v-show="partnerId === entity.partnerId"
                      :class="{ 'd-block': row.masterCanChange && entity.canChange, 'd-none': !(row.masterCanChange && entity.canChange) }"
                      @click="saveMasterCartonAsync(row.palletID, row.masterIdent, row.isNewMc)">
                      Save MC
                    </button>
                    <button class="btn btn-sm mb-1 btn-outline-warning" v-show="!row.masterCanChange && entity.canChange && partnerId === entity.partnerId" @click="openTakeOwnership(row)">{{ $t('common.takeOwnership') }} 🗝️</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ShipmentSummary :summary="summary" :summaryTotal="summaryTotal" />

      <TakeOwnershipModal
        @close="selectMasterCartonOwnership = null"
        @success="takeOwnershipSuccess"
        :masterCarton="selectMasterCartonOwnership"
        :shipmentId="entity.id" />

      <MasterCartonModal
        @close="selectMasterCartonParameters = null"
        @success="editMasterCartonSuccessAsync"
        @delete="deleteMasterCartonAsync"
        :canChange="entity.canChange"
        :masterCarton="selectMasterCartonParameters"
      />

    </div>

  </div>

  <SelectPurchaseOrderItem
    :visible="!!selectSkuParameters"
    :po="lastPurchaseOrder"
    @close="selectSkuParameters = null"
    @selected="sku => addPurchaseOrderItem(sku, selectSkuParameters.palletID, selectSkuParameters.masterIdent, selectSkuParameters.innerIdent)"
    @selectedPo="po => lastPurchaseOrder = po"
    />


  `,
  setup(props, { emit }) {
    const { shipmentTypes } = useEnums()
    const route = VueRouter.useRoute()
    const router = VueRouter.useRouter()
    const { instance } = useMsal()
    const { partnerId } = useIsAuthorized()
    const { summary, loadShipmentSummary, summaryTotal } = useSummary()
    const {
      entity,
      rows,
      addPallet,
      addInnerCarton,
      addMasterCarton,
      _getPallet,
      _getInnerCarton,
      removeSku,
      flatMasterCartons,
      _getMasterCarton,
      loadShipmentAsync,
      saveShipmentAsync,
      purchaseOrders,
      _getSku,
      poiShippedQuantityExt,
      poiShippedQuantityHere,
      hasUnsavedMasterCarton,
    } = useShipment(instance, route, router)

    const lastPurchaseOrder = ref(null)

    // select POI parameters
    const selectSkuParameters = ref(null)
    const showSelectPurchaseOrderItem = (row, withHiddenInner = false) => {
      if (withHiddenInner) {
        const masterCarton = _getMasterCarton(row.palletID, row.masterIdent)
        const innerCarton =
          masterCarton.innerCartons.find((c) => c.isHidden) || addInnerCarton(row.palletID, row.masterIdent, true)
        selectSkuParameters.value = {
          ...row,
          innerIdent: innerCarton.ident,
          skuCount: 0,
        }
      } else {
        selectSkuParameters.value = row
      }
    }
    const addPurchaseOrderItem = (poi, palletID, masterIdent, innerIdent) => {
      if (!poi.po) {
        throw new Error("PO is required")
      }
      entity.value.pallets.forEach((pallet) => {
        pallet.masterCartons.forEach((mc) => {
          mc.innerCartons.forEach((ic) => {
            ic.items.forEach((poi) => (poi.isNew = false))
          })
        })
      })
      const innerCarton = _getInnerCarton(palletID, masterIdent, innerIdent)
      poi.isNew = true
      innerCarton.items.push(poi)
      selectSkuParameters.value = null
    }

    // edit master carton
    const selectMasterCartonParameters = ref(null)
    const editMasterCartonSuccessAsync = async (result) => {
      const masterCarton = _getMasterCarton(result.palletID, result.masterIdent)
      masterCarton.grossWeight = result.grossWeight
      masterCarton.netWeight = result.netWeight
      masterCarton.note = result.note
      masterCarton.length = result.length
      masterCarton.width = result.width
      masterCarton.height = result.height
      masterCarton.quantity = result.masterQuantity
      masterCarton.comment = result.comment
      selectMasterCartonParameters.value = null
      await saveMasterCartonAsync(result.palletID, result.masterIdent, result.isNewMc)
    }
    const deleteMasterCartonAsync = async (row) => {
      const masterCarton = _getMasterCarton(row.palletID, row.masterIdent)
      if (!masterCarton) {
        throw new Error("Master carton not found " + row.masterIdent)
      }
      if (!masterCarton.canChange) {
        throw new Error("Master carton cannot be deleted " + row.masterIdent)
      }
      selectMasterCartonParameters.value = null
      const result = await deleteEntityAsync(instance, `MasterCarton/${entity.value.id}/${row.masterIdent}`)
      if (result) {
        setSuccess("Zbrisano.", true)
        await loadShipmentAsync()
        await loadShipmentSummary()
      } else {
        setError("Napaka pri brisanju.")
      }
    }

    // take master carton ownership
    const selectMasterCartonOwnership = ref(null)
    const openTakeOwnership = (row) =>
      (selectMasterCartonOwnership.value = _getMasterCarton(row.palletID, row.masterIdent))
    const takeOwnershipSuccess = async (result) => {
      const masterCarton = _getMasterCarton(result.masterCartonPalletId, result.ident)
      masterCarton.canChange = result.canChange
      await loadShipmentSummary(route.params.id)
    }

    const takeShipmentOwnership = async (e) => {
      const result = await patchEntityAsync(instance, `Shipment`, entity.value)
      if (result) {
        await loadShipmentAsync()
        setSuccess("Prevzeto.", true)
      } else {
        setError("Napaka pri prevzemanju.")
      }
    }

    ////////////////////

    const masterCartons = computed(() => entity.value?.pallets.flatMap((sp) => sp.masterCartons))

    const isValidForPrinting = computed(
      () => !masterCartons.value.find((mc) => !mc.grossWeight) && !masterCartons.value.find((mc) => !mc.netWeight)
    )

    const saveMasterCartonAsync = async (palletID, masterIdent, isNewMc) => {
      const masterCarton = _getMasterCarton(palletID, masterIdent)
      const result = await saveEntityAsync(instance, `MasterCarton/${entity.value.id}`, masterCarton, isNewMc)
      if (result && result.id) {
        setSuccess("Shranjeno.", true)
        await loadShipmentAsync()
        await loadShipmentSummary()
      } else {
        setError("Napaka pri shranjevanju.")
      }
    }

    const localSaveEntityAsync = async () => {
      const result = await saveShipmentAsync()
      await loadShipmentSummary(route.params.id)
      return result
    }

    const markAsAsync = async (status) => {
      const previousStatus = entity.value.status
      console.log("markAsAsync", status)
      entity.value.status = status
      if (!(await localSaveEntityAsync())) {
        console.log("markAsAsync failed")
        entity.value.status = previousStatus
      }
    }

    const defaultDimensions = computed(() => {
      if (entity.value.pallets.length && entity.value.pallets[0].masterCartons.length) {
        const mc = entity.value.pallets[0].masterCartons[0]
        return { length: mc.length, width: mc.width, height: mc.height }
      }
      return { length: 0, width: 0, height: 0 }
    })

    const showMasterCartonEditor = (row) => {
      selectMasterCartonParameters.value = row
      if (!row.length) selectMasterCartonParameters.value.length = defaultDimensions.value.length
      if (!row.width) selectMasterCartonParameters.value.width = defaultDimensions.value.width
      if (!row.height) selectMasterCartonParameters.value.height = defaultDimensions.value.height
      if (!row.masterQuantity) selectMasterCartonParameters.value.masterQuantity = 1
    }

    const updateShipmentQuantity = (e, row) => {
      // TODO: doesn't work well for multiple same SKUs in same inner carton
      const sku = _getSku(row)
      const newQuantiy = parseInt(e.target.value)
      sku.quantity = isNaN(newQuantiy) ? 0 : newQuantiy
    }

    watch(
      () => route.params.id,
      async () => {
        await loadShipmentAsync()
        await loadShipmentSummary(route.params.id)
      }
    )

    const handleKey = (e) => {
      if (
        !entity.value.isNew &&
        entity.value.status === "Preparing" &&
        e.ctrlKey &&
        e.code === "Space" &&
        entity.value.hasPallets === false
      ) {
        addMasterCarton("P-1")
      }
    }

    onMounted(async () => {
      await loadShipmentAsync()
      await loadShipmentSummary(route.params.id)
      document.addEventListener("keydown", handleKey)
    })

    onUnmounted(() => {
      document.removeEventListener("keydown", handleKey)
    })

    const anyRowIsQuantityZero = computed(() => rows.value.some((r) => r.quantity === 0))

    return {
      partnerId,
      entity,
      isValidForPrinting,
      summary,
      summaryTotal,
      rows,
      shipmentTypes,
      localSaveEntityAsync,
      addPallet,
      addMasterCarton,
      addInnerCarton,
      addPurchaseOrderItem,
      showMasterCartonEditor,
      flatMasterCartons,
      selectSkuParameters,
      showSelectPurchaseOrderItem,
      purchaseOrders,
      lastPurchaseOrder,
      removeSku,
      updateShipmentQuantity,
      poiShippedQuantityExt,
      poiShippedQuantityHere,
      selectMasterCartonParameters,
      deleteMasterCartonAsync,
      takeOwnershipSuccess,
      editMasterCartonSuccessAsync,
      defaultDimensions,
      markAsAsync,
      selectMasterCartonOwnership,
      openTakeOwnership,
      takeShipmentOwnership,
      anyRowIsQuantityZero,
      saveMasterCartonAsync,
      masterCartons,
      hasUnsavedMasterCarton,
    }
  },
}
